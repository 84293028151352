var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v("Feedback")]),
      _vm.state === "submitted"
        ? [_vm._v(" Thank you for your feedback ")]
        : _vm.state === "submitting"
        ? [_c("Loading", { attrs: { label: "Submitting" } })]
        : _vm.state === "error"
        ? [
            _c("ErrorAlert", [
              _vm._v("Failed to submit feedback: " + _vm._s(_vm.error))
            ])
          ]
        : [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [
                    _vm._v(" Message "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.content,
                          expression: "form.content"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { required: "" },
                      domProps: { value: _vm.form.content },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "content", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                false
                  ? _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        [
                          _vm._v(" Rating "),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v("(optional)")
                          ]),
                          _c("BFormRating", {
                            model: {
                              value: _vm.form.rating,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "rating", $$v)
                              },
                              expression: "form.rating"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._m(0)
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-group" }, [
      _c(
        "button",
        { staticClass: "btn btn-outline-primary", attrs: { type: "submit" } },
        [_vm._v("Submit")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }